import { combineReducers } from 'redux'
import authReducer from './authReducer'
import siteModal from './siteModal'
import transactionReducer from './transactionReducer'
import userReducer from './userReducer'
import fileReducer from './fileReducer'
import billReducer from './billReducer'
import categoryReducer from './categoryReducer'

const rootReducer = combineReducers({
  auth: authReducer,
  bill: billReducer,
  categories: categoryReducer,
  files: fileReducer,
  siteModal: siteModal,
  transactions: transactionReducer,
  user: userReducer,
})

export default rootReducer
