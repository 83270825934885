export const regExp = {
  //findPrices: /\$[0-9]+(\.)?(\s)?[0-9]+/g,
  // Find patterns such as 21.99 of 21,99
  findPrices: /[0-9]+(\.|,)?(\s)?[0-9]+/g,
  amount:
    /(?:(?:\$|€|£|¥|₹|₣|₱|₪|₨|₺|₽|₴|₸)\s?)?\d{1,3}(?:[,.]\d{3})*(?:[,.]\d{2})?/,
  date: {
    //mmddyyyy: '\b\d{1,2}\/\d{1,2}\/\d{4}\b',
    ddmmyyyy: '\\d{1,2}(/|-)\\d{1,2}(/|-)\\d{4}',
    //Date in the format 4-Jul-2023: dd-Mon-yyyy
    ddMonyyy:
      '\\d{1,2}-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-\\d{4}',
    //yyyymmdd: '\\d{4}(\/|-)\\d{1,2}(\/|-)\\d{1,2}'
    //Counting till actual year
    yyymmdd: '20([0-1][0-9])|(202[0-3])(-|/)d{1,2}(-|/)d{1,2}',
    mmddSuffix: '(-|/)d{1,2}(-|/)d{1,2}',
    mmddPrefix: 'd{1,2}(-|/)d{1,2}(-|/)',
  },
}

//Create a RegexToFindDate
export const getRegExpDate = (text) => {
  //Find the actual year (fastest way for the algorithm)
  //find the year first , format could be : 2023 or 23
  //Once the year has been found check whether is part of the date and the format
  // Check for the following formats: yy-mm-dd, dd-mm-yy, yyyy-mm-dd, dd-mm-yyyy
  const d = new Date()
  const actualYear = d.getFullYear()

  //Gives 23, from 2023 to search for 23-12-24 format
  const yearPart1 = parseInt(actualYear.toString().substring(0, 2))
  const yearPart2 = parseInt(actualYear.toString().substring(2, 4))

  //Check if we are not at the beginning of the year such as 2022, in that case we have to use the year before to compare as well

  if (text.includes(actualYear)) {
    //Even better
    const regExpBetter1 = `(${yearPart1})?(${yearPart2}(-|\/)([0-1][0-9])(-|\/)[0-3][0-9])`
    const regExpBetter2 = `([0-3][0-9](-|\/)?[0-3][0-9](-|\/)?(${yearPart1})?${yearPart2})`
    const regExpBetter = `${regExpBetter1}|${regExpBetter2}`
    return regExpBetter
  }
  return false
}

/**
 * Having prefix ['SOUS','SUB'] and word "TOTAL"
 * Will create an array like ['SOUS TOTAL','SOUS-TOTAL','SOUS/TOTAL','SUB TOTAL']
 *
 * @param {Array} prefix like ['SOUS','SUB']
 * @param {String} word TOTAL
 * @returns {Array}
 */
const createArrayCombinationFromPrefix = (prefix, word) => {
  const signs = ' -/%*' //include the empty space at the beginning
  let arr = []
  for (const char of signs) {
    for (const pre of prefix) {
      arr.push(pre + char + word)
    }
  }
  return arr
}

export const createSubTotalArr = () => {
  return createArrayCombinationFromPrefix(['SOUS', 'SUB'], 'TOTAL')
}

export const createTotalArr = () => {
  const firstPart = createArrayCombinationFromPrefix(['VENTE'], 'TOTAL')
  const secondPart = createArrayCombinationFromPrefix(
    ['TOTAL', 'VENTES'],
    'SALES'
  )
  return [...firstPart, 'TOTAL', 'TOTALE', '6¢101AL', secondPart]
}
