import React from 'react'
import { Box, Typography } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

export default function NoData() {
  return (
    <Box sx={{ p: 4, textAlign: 'center', pt: 10 }}>
      <ErrorOutlineIcon c0lor="primary" sx={{ fontSize: '2.4rem' }} />
      <Typography component="p" variant="h5" color="secondary">
        No data at the present time
      </Typography>
    </Box>
  )
}
