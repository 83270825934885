import React, { useRef } from 'react'
import dayjs from 'dayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

export default function DateMonthYear(props) {
  const dateRef = useRef({ month: '', year: '' })

  const onCloseDate = () => {
    props.updateDate(dateRef.current)
  }

  const onChangeMonthYear = (val) => {
    dateRef.current = { month: val.$M + 1, year: val.$y }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker
          views={props.views}
          openTo="month"
          defaultValue={dayjs()}
          onChange={(val) => onChangeMonthYear(val)}
          onClose={onCloseDate}
          disableFuture
        />
      </DemoContainer>
    </LocalizationProvider>
  )
}
