// eslint-disable-next-line import/no-anonymous-default-export
export default (openClose, content, allowToClose = true) => {
  return {
    type: 'openModal',
    payload: {
      openClose,
      content,
      allowToClose,
    },
  }
}
