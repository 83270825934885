import React from 'react'
import { useFormContext } from 'react-hook-form'
import { ListSubheader, MenuItem, Select } from '@mui/material'

export default function CategoriesElement(props) {
  const { register } = useFormContext()
  if (!props.category && !props.categories) return <></>
  return (
    <Select
      label="Category"
      variant="standard"
      value={props.category}
      {...register('category', {
        required: 'Category is required',
        value: props.category,
        onChange: props.setMyCategory,
      })}
    >
      {props.categories.map((item, i) => {
        let children = []
        children.push(<ListSubheader></ListSubheader>)
        children.push(
          <MenuItem key={i} value={item.name}>
            {item.name}
          </MenuItem>
        )
        return children
      })}
    </Select>
  )
}
