import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import setBill from '../../actions/setBill'
import { useFormContext } from 'react-hook-form'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

export default function SelectElement(props) {
  //
  const { register, clearErrors, setValue } = useFormContext()
  const dispatch = useDispatch()
  const bill = useSelector((state) => state.bill)
  const [state, setState] = useState('')

  const checkSetState = (val) => {
    if (props.options.includes(val)) {
      setState(val)
      const obj = { ...bill }
      obj[props.name] = val
      dispatch(setBill(obj))
      clearErrors(props.name)
      setValue(props.name, val)
    }
  }
  useEffect(() => {
    const val = props.options[0]
    if (props.options.length) {
      setState(props.options[0])
      setValue(props.name, val)
    }
  }, [])

  return (
    <Select
      value={state}
      label={props.name}
      variant="standard"
      {...register(props.name, {
        required: props.name + ' is required',
        value: state,
        onChange: (e) => checkSetState(e.target.value),
      })}
    >
      {props.options.length
        ? props.options.map((option, i) => {
            return (
              <MenuItem key={`${i}-${option}`} value={option}>
                {option}
              </MenuItem>
            )
          })
        : ''}
    </Select>
  )
}
