import Swal from 'sweetalert2'

export const getSwal = (
  title,
  text = '',
  icon = 'error',
  dangerMode = false
) => {
  Swal.fire({
    title,
    text,
    icon,
    dangerMode,
  })
}
