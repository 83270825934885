import React from 'react'
import TransactionForm from '../components/molecules/TransactionForm'
import PicReader from '../components/PicReader'

export default function AddTransaction(props) {
  return (
    <>
      {/* <h1>Expense Page</h1> */}
      <h1>Add Expenses</h1>
      <br></br>
      <PicReader />
      <br />
      <TransactionForm />
    </>
  )
}
