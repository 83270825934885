import React from 'react'
import { useTheme } from '@mui/material/styles'
import NoData from './atoms/NoData'
import Spinner from '../utility/Spinner/Spinner'

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import Title from './Title'

const LoadedComponent = (props) => {
  if (!props.data.length) return <NoData />
  const data = props.data.map((item) => {
    return {
      ...item,
      amount: parseFloat(item.amount.toFixed(2)),
    }
  })
  return (
    <ResponsiveContainer>
      <LineChart
        data={data}
        margin={{
          top: 16,
          right: 16,
          bottom: 0,
          left: 24,
        }}
      >
        <XAxis
          dataKey={props.keyX}
          stroke={props.theme.palette.text.secondary}
          style={props.theme.typography.body2}
        ></XAxis>
        <YAxis
          stroke={props.theme.palette.text.secondary}
          style={props.theme.typography.body2}
        >
          <Label
            angle={270}
            position="left"
            style={{
              textAnchor: 'middle',
              fill: props.theme.palette.text.primary,
              ...props.theme.typography.body1,
            }}
          >
            Expenses ($)
          </Label>
        </YAxis>
        <Tooltip />
        <Line
          isAnimationActive={false}
          type="monotone"
          dataKey={props.keyY}
          stroke={props.theme.palette.primary.main}
          activeDot={{ r: 3 }}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default function Chart(props) {
  const theme = useTheme()

  return (
    <React.Fragment>
      <Title>{props.title || ''}</Title>
      {props.loading ? (
        <Spinner />
      ) : (
        <LoadedComponent {...props} theme={theme} />
      )}
    </React.Fragment>
  )
}
