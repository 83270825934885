import React from 'react'
import { Container, Box, Grid, Button, Link, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import openModal from '../actions/openModal'
import { Colors } from '../utility/theme'
import Login from '../utility/NavBar/Login'
import params from '../configs/params'
import detectMobile from '../hooks/detectMobile'

export default function Public() {
  const isMobile = detectMobile()

  //TODO Render from css
  const css = {
    title: {
      fontSize: isMobile ? '2.5rem' : '4.5rem',
    },
    subTitle: {
      fontSize: isMobile ? '1.3rem' : '',
    },
    forgotPassword: {
      fontSize: isMobile ? '1rem' : '1.25rem',
    },
    grid: {
      button: {
        mt: isMobile ? 4 : 6,
      },
    },
    opacity: isMobile ? 0.5 : 0.3,
  }

  //TODO implement i18n
  const lang = require(`../lang/${params.lang || 'en'}.json`)
  //TODO Define sizes
  const homePic = require('../../src/images/home/home-lg.jpg')
  const dispatch = useDispatch()

  return (
    <>
      <Box
        component="main"
        sx={{
          backgroundImage: `url(${homePic})`,
          backgroundSize: 'cover',
          flexGrow: 1,
          maxWidth: '100%',
          height: '100vh',
          m: 0,
          p: 0,
        }}
      >
        <Container
          maxWidth="xxl"
          sx={{
            m: 0,
            backgroundColor: `rgb(0,0,0,${css.opacity})`,
            height: '100vh',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            pt: '3.45rem',
          }}
        >
          <Grid container spacing={0} sx={{ maxWidth: 'md' }}>
            <Grid item xs={12} md={8} lg={12}>
              <Typography
                component="h1"
                variant="h2"
                sx={{
                  textAlign: 'center',
                  fontSize: css.title.fontSize,
                  fontWeight: '400',
                  color: 'white',
                }}
              >
                {lang.pages.home.title}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={12} sx={{ mt: 0 }}>
              <Typography
                component="p"
                variant="h5"
                sx={{
                  mt: 2,
                  fontSIze: css.subTitle.fontSize,
                  textAlign: 'center',
                  fontWeight: '400',
                  color: Colors.greenLight,
                }}
              >
                {lang.pages.home.subTitle}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={8}
              lg={12}
              sx={{ textAlign: 'center', mt: css.grid.button.mt, mb: 2 }}
            >
              <Button
                variant="contained"
                color="success"
                sx={{
                  m: 0,
                  p: 0,
                  width: '50%',
                  height: '40px',
                  color: Colors.main,
                }}
                onClick={() =>
                  dispatch(
                    openModal('open', <Login type="signUp" title="Sign up" />)
                  )
                }
              >
                {lang.components.signup.button}
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              lg={12}
              sx={{ textAlign: 'center', mt: 2 }}
            >
              <Link
                href="#"
                sx={{
                  color: 'white',
                  mt: 0,
                  fontWeight: 400,
                  fontSize: css.forgotPassword.fontSize,
                  textDecoration: 'none',
                  ':hover': { color: '#96AFB8' },
                }}
                onClick={() =>
                  dispatch(
                    openModal(
                      'open',
                      <Login type="forgotPassword" title="Forgot Password" />
                    )
                  )
                }
              >
                {lang.components.forgotPassword.button}
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}
