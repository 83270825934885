import { Today } from './date'

export const validateDate = (date) => {
  if (date && date !== 'Invalid date') {
    const today = Today.YYYYMMDD.split('-').join('')
    const targetDate = date.split('-').join('')
    return targetDate < today
  }
  return false
}
