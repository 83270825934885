import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'
import setBill from '../../actions/setBill'
import resetBill from '../../actions/resetBill'
import api from '../../configs/api'
import axios from 'axios'
import moment from 'moment'
import Title from '../Title'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'
import AmountElement from '../FormElement/AmountElement'
import SelectElement from '../FormElement/SelectElement'
import CategoriesElement from '../FormElement/CategoriesElement'
import { getSwal } from '../../utility/notifications'
import { getUser } from '../../utility/requests'
import { getCategoryRules } from '../../utility/rules'
import { validateDate } from '../../utility/validations'
import { Today } from '../../utility/date'

export default function TransactionForm(props) {
  const dispatch = useDispatch()
  const bill = useSelector((state) => state.bill)
  const methods = useForm()
  const [categories, setCategories] = useState('')
  const [category, setCategory] = useState('')
  const [name, setName] = useState('')
  const [date, setDate] = useState('')
  const [amount, setAmount] = useState('')
  const [sources, setSources] = useState('')
  const [disabledBtn, setDisabledBtn] = useState(false)

  //
  useEffect(() => {
    clearValues(methods)
    const fetchUser = async () => {
      const user = await getUser()
      let categories = []
      user.categories.forEach((cat, i) => {
        categories.push({ id: i, name: cat })
      })
      setSources(user.sources)
      setCategory(categories[0].name)
      setCategories(categories)
    }
    fetchUser()
    const now = moment().format('YYYY-MM-DD')
    methods.setValue('date', now)
    setDate(now)
    dispatch(resetBill())
    setBill({ ...bill, date: now })
  }, [])

  useEffect(() => {
    if (bill.amount) {
      setAmount(bill.amount)
      methods.setValue('amount', bill.amount)
    }
  }, [bill.amount])

  useEffect(() => {
    if (bill.name) {
      setName(bill.name)
      methods.setValue('name', bill.name)
    }
  }, [bill.name])

  useEffect(() => {
    if (bill.date) {
      const date = validateDate(bill.date) ? bill.date : Today.YYYYMMDD
      setDate(date)
      methods.setValue('date', date)
    }
  }, [bill.date])

  const createTransaction = async (formData) => {
    const resp = await axios.post(`${api.transactions.transaction}`, formData, {
      withCredentials: true,
    })
    return resp.data.data
  }

  const fiscalData = (data) => {
    const c = 'color: green;background: white;'
    const amount = parseFloat(data.amount)
    const rules = getCategoryRules(data)
    if (amount > rules.max) {
      getSwal(
        'Value too high!',
        `Value cannot be higher than $${parseFloat(rules.max).toFixed(
          2
        )} for this category`
      )
      return false
    }
    return true
  }

  const onSubmit = async (data) => {
    try {
      setDisabledBtn(true)
      const result = fiscalData(data)
      //TODO: Temporary hack
      data.cost = data.cost === 'Variable' ? 'Var' : 'Fix'
      if (result) {
        console.log(
          `%c create transaction data: `,
          'color: green;background: white;',
          data
        )
        const respCreate = await createTransaction(data)
        console.log(`%c respCreate: `, 'color:yellow', respCreate)
        //dispatch(updateUser(user));
        clearValues(methods)
        getSwal('Success', 'Your transaction has been saved', 'success')
      }
    } catch (error) {
      console.error('ERRROROOOOR', error)
      getSwal(
        'Error',
        'There was an error attempting to save your transaction, please try again!'
      )
    }
    setDisabledBtn(false)
  }

  const clearValues = (methods) => {
    //methods.reset({})
    methods.setValue('date', '')
    methods.setValue('name', '')
    methods.setValue('amount', '')
  }

  const getError = (methods, propsName) => {
    return !!methods.formState.errors[propsName]
  }
  const getHelpError = (methods, propsName) => {
    return methods.formState.errors[propsName] &&
      typeof methods.formState.errors[propsName].message !== 'undefined'
      ? methods.formState.errors[propsName].message
      : ''
  }

  //maxWidth="xs"
  return (
    <FormProvider {...methods}>
      <Container maxWidth="xs" sx={{ ml: '0', pl: '0', p: 0 }}>
        <Title>Single Expense</Title>
        <Box
          component="form"
          onSubmit={methods.handleSubmit(onSubmit)}
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
          noValidate
        >
          <TextField
            required
            variant="standard"
            label="Date"
            {...methods.register('date', { required: 'Date is required' })}
            error={getError(methods, 'date')}
            helperText={getHelpError(methods, 'date')}
            inputProps={{ inputMode: 'date' }}
            //We add these lines to avoid antifocus with label
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
          <TextField
            required
            name="name"
            variant="standard"
            label="Name"
            {...methods.register('name', { required: 'Field is required' })}
            error={getError(methods, 'name')}
            helperText={getHelpError(methods, 'name')}
            //We add these lines to avoid antifocus with label
            autoComplete={name}
            // defaultValue={name}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <TextField
            label="Description"
            variant="standard"
            {...methods.register('description')}
          />
          <AmountElement
            amount={amount}
            errors={methods.formState.errors.amount}
          />
          <br />
          <FormControl>
            <InputLabel required id="select-category" sx={{ ml: '-14px' }}>
              Category
            </InputLabel>
            {category ? (
              <CategoriesElement
                categories={categories}
                category={category}
                setMyCategory={(e) => setCategory(e.target.value)}
              />
            ) : (
              ''
            )}
          </FormControl>
          <br />
          <FormControl>
            <InputLabel id="select-cost" required sx={{ ml: '-14px' }}>
              Cost
            </InputLabel>
            <SelectElement options={['Variable', 'Fixed']} name="cost" />
          </FormControl>
          <FormControl sx={{ display: 'none' }}>
            <InputLabel id="select-cost" required sx={{ ml: '-14px' }}>
              Type
            </InputLabel>
            <SelectElement
              options={['Expenses', 'Transaction', 'Revenue']}
              name="type"
            />
          </FormControl>
          <br />
          <FormControl>
            <InputLabel id="select-source" required sx={{ ml: '-14px' }}>
              Payment Option
            </InputLabel>
            {sources.length ? (
              <SelectElement options={sources} name="source" />
            ) : (
              ''
            )}
          </FormControl>
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={disabledBtn}
          >
            Save
          </Button>
        </Box>
      </Container>
    </FormProvider>
  )
}
