import React from 'react'
import { useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import openModal from '../../actions/openModal'
import Login from './Login'
import axios from 'axios'
import api from '../../configs/api'
import logoutAction from '../../actions/logoutAction'

const logOut = async (dispatch) => {
  dispatch(logoutAction())
  await axios.post(api.auth.logout, {}, { withCredentials: true })
}

export default function LoginLogout(props) {
  const dispatch = useDispatch()
  if (!props.email) {
    return (
      <>
        <Button
          color="success"
          onClick={() =>
            dispatch(openModal('open', <Login type="signUp" title="Sign up" />))
          }
        >
          Sign Up
        </Button>
        <Button
          color="inherit"
          onClick={() =>
            dispatch(openModal('open', <Login type="logIn" title="Log In" />))
          }
        >
          Login
        </Button>
      </>
    )
  }

  return (
    <>
      <Button color="inherit" onClick={() => logOut(dispatch)}>
        Log out
      </Button>
    </>
  )
}
