import React, { useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import {
  getError,
  getHelpError,
} from '../../components/FormElement/formErrors.js'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { useTheme, ThemeProvider } from '@mui/material/styles'
import { getSwal } from '../notifications'
import axios from 'axios'
import { useDispatch } from 'react-redux'

import api from '../../configs/api'
import Copyright from '../Copyright/Copyright'

import updateUser from '../../actions/updateUser'
import regAction from '../../actions/regAction'
import openModal from '../../actions/openModal'

export default function Login(props) {
  const dispatch = useDispatch()
  const theme = useTheme()
  const methods = useForm()

  const handleSubmitLogin = async (authInfo) => {
    if (props.type === 'resetPassword') {
      authInfo.token = props.token
      return resetPassword(authInfo)
    }
    if (props.type === 'forgotPassword') {
      return forgotPassword(authInfo)
    }
    if (props.type === 'signUp') {
      return register(authInfo)
    }
    if (props.type === 'logIn') {
      return login(authInfo)
    }
  }

  const forgotPassword = async (authInfo) => {
    try {
      const resp = await axios.post(api.auth.forgotPassword, authInfo, {
        withCredentials: false,
      })
      if (resp.data.token) {
        dispatch(openModal('close', ''))
        getSwal(
          'Email Sent',
          'Please verify your email and also your spam folder',
          'success'
        )
      }
      return true
    } catch (error) {
      console.error('Forgot password error', error)
      const errorMsg = error.response?.data?.error
        ? error.response?.data?.error
        : 'Invalid nEtry'
      getSwal('Error', errorMsg)
      return false
    }
  }
  const resetPassword = async (authInfo) => {
    try {
      const resp = await axios.post(api.auth.resetPassword, authInfo, {
        withCredentials: false,
      })

      if (resp.data.data) {
        login(authInfo)
      }
    } catch (error) {
      console.error('reset password error', error)
      const errorMsg = error.response?.data?.error
        ? error.response?.data?.error
        : 'Invalid nEtry'
      getSwal('Error', errorMsg)
      return false
    }
  }

  const loginRegister = async (authInfo, process) => {
    if (!['register', 'logIn'].includes(process)) {
      return false
    }
    try {
      const resp = await axios.post(api.auth[process], authInfo, {
        withCredentials: true,
      })
      if (resp.data.token) {
        const { data } = await axios.get(api.auth.getMe, {
          withCredentials: true,
        })
        const user = data.data
        if (user.email === authInfo.email) {
          dispatch(
            regAction({ token: resp.data.token, email: user.email, process })
          )
          dispatch(updateUser(user))
        }
      }
      return true
    } catch (error) {
      const errorMsg = error.response?.data?.error
        ? error.response?.data?.error
        : 'Invalid Entry'
      getSwal('Error', errorMsg)
      return false
    }
  }

  const register = async (authInfo) => {
    return loginRegister(authInfo, 'register')
  }

  const login = async (authInfo) => {
    return loginRegister(authInfo, 'logIn')
  }

  const getRepeatPasswordError = (methods, propsName) => {
    const password = methods.getValues('password')
    const repeatPassword = methods.getValues('repeatPassword')
    const match = password === repeatPassword
    return (
      !!methods.formState.errors[propsName] ||
      (!match && password !== '' && repeatPassword !== '')
    )
  }

  const getRepeatPasswordHelpError = (methods, propsName) => {
    const password = methods.getValues('password')
    const repeatPassword = methods.getValues('repeatPassword')
    const match = password === repeatPassword
    if (!match && password !== '' && repeatPassword !== '') {
      return 'Password must match'
    }
    return getHelpError(methods, 'repeatPassword')
  }

  //Button Modal title
  const getButtonTitle = (type) => {
    if (['signUp', 'logIn', 'resetPassword'].includes(type)) {
      return 'Sign In'
    }
    if (['forgotPassword'].includes(type)) {
      return 'Reset Password'
    }
  }

  useEffect(() => {
    if (props.type === 'resetPassword' && props.email) {
      methods.setValue('email', props.email)
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {props.title}
          </Typography>
          <FormProvider {...methods}>
            <Box
              component="form"
              onSubmit={methods.handleSubmit(handleSubmitLogin)}
              noValidate
              sx={{ mt: 1 }}
            >
              {['signUp'].includes(props.type) ? (
                <TextField
                  margin="normal"
                  required
                  variant="standard"
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  {...methods.register('name', {
                    required: 'Name is required',
                  })}
                  error={getError(methods, 'name')}
                  helperText={getHelpError(methods, 'name')}
                />
              ) : (
                ''
              )}
              <TextField
                margin="normal"
                required
                variant="standard"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                {...methods.register('email', {
                  required: 'Email is required',
                })}
                error={getError(methods, 'email')}
                helperText={getHelpError(methods, 'email')}
              />
              {['resetPassword', 'logIn', 'signUp'].includes(props.type) ? (
                <TextField
                  margin="normal"
                  variant="standard"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  {...methods.register('password', {
                    required: 'Password is required',
                  })}
                  error={getError(methods, 'password')}
                  helperText={getHelpError(methods, 'password')}
                />
              ) : (
                ''
              )}

              {['resetPassword'].includes(props.type) ? (
                <TextField
                  margin="normal"
                  variant="standard"
                  required
                  fullWidth
                  name="repeatPassword"
                  label="Repeat Password"
                  type="password"
                  id="repeatPassword"
                  autoComplete="current-password"
                  {...methods.register('repeatPassword', {
                    required: 'Password is required',
                  })}
                  error={getRepeatPasswordError(methods, 'repeatPassword')}
                  helperText={getRepeatPasswordHelpError(
                    methods,
                    'repeatPassword'
                  )}
                />
              ) : (
                ''
              )}
              {['signUp', 'logIn'].includes(props.type) ? (
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
              ) : (
                ''
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {getButtonTitle(props.type)}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    href="#"
                    variant="body2"
                    onClick={() =>
                      dispatch(
                        openModal(
                          'open',
                          <Login
                            type="forgotPassword"
                            title="Forgot Password"
                          />
                        )
                      )
                    }
                  >
                    {props.type === 'logIn' ? 'Forgot password?' : ''}
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    href="#"
                    variant="body2"
                    onClick={() =>
                      dispatch(
                        openModal(
                          'open',
                          <Login type="signUp" title="Sign up" />
                        )
                      )
                    }
                  >
                    {props.type === 'logIn'
                      ? "Don't have an account? sign up"
                      : ''}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </FormProvider>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  )
}
