import { createSubTotalArr, createTotalArr } from '../../utility/regExp/regExp'
import { regExp, getRegExpDate } from '../../utility/regExp/regExp'
import { matchLabelNames } from '../regExp/matchLabels'

export const getName = (arr) => {
  if (arr.length < 4) return ''
  const name = arr.find((item) => item.text.length > 10).text
  // TESTING FINDING PATTERNS
  //if (name.match(/KARINA(\s)?MUNOZ/)) return 'Metro'
  const match = matchLabelNames(name)
  if (match) {
    return match
  }
  return name
}

const transformDateFormat = (dateString) => {
  // Split the date string into day, month, and year
  const dateParts = dateString.split(/-|\//g)
  if (dateParts.length === 3) {
    // Rearrange the date parts to yyyy-mm-dd format
    const transformedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
    return transformedDate
  }
  // Return original string if the format is incorrect
  return dateString
}

const isValidDateFormat = (dateString) => {
  const regex = /^\d{4}(-|\/)\d{2}(-|\/)\d{2}$/
  return regex.test(dateString)
}

export const getDate = (text) => {
  const c = 'color: green'
  const regExpDate = getRegExpDate(text)
  const match = text.match(new RegExp(regExpDate, 'i'))
  if (isValidDateFormat(match?.[0])) {
    return match[0].replace(/\/|\\/g, '-')
  }
  if (match?.length) {
    return transformDateFormat(match[0])
  }

  const keys = Object.keys(regExp.date)
  keys.forEach((key) => {
    const match = text.match(new RegExp(regExp.date[key], 'i'))
    if (match?.length && isValidDateFormat(match?.[0])) return match[0]
  })
  return ''
}

export const findSubTotalValue = (arr) => {
  const subTotalArr = createSubTotalArr()
  const subTotalRegExp = new RegExp(subTotalArr.join('|'), 'i')
  const subTotalLine = arr.find((item) =>
    item.text !== 'undefined' ? item.text.match(subTotalRegExp) : ''
  )
  const subTotalIndex = arr.findIndex((item) => item.text.match(subTotalRegExp))
  const subTotal =
    subTotalLine && typeof subTotalLine.text !== 'undefined'
      ? subTotalLine.text
      : ''
  const subTotalNumber = 0
  //
  // const subTotalNumber =
  //   subTotal && typeof subTotal !== 'undefined'
  //     ? subTotal.match(regExp.findPrices)[0].replace(' ', '')
  //     :
  //     0
  return { subTotal, subTotalIndex, subTotalNumber }
}

export const findTotalValue = (arr, subTotalIndex) => {
  const totalArr = createTotalArr()
  const totalRegExp = new RegExp('(' + totalArr.join('|') + ')', 'i')
  let totalLine = arr.find(
    (item, i) =>
      typeof item.text !== 'undefined' &&
      item.text.match(totalRegExp) &&
      i !== subTotalIndex
  )
  let totalNumber =
    totalLine && typeof totalLine.words !== 'undefined' ? totalLine.words : ''
  if (!totalNumber) {
    totalLine = 0
  } else {
    totalNumber = totalNumber
      .find((item) => item?.text?.match(regExp.findPrices))
      .text.replace(',', '.')
      .replace('$', '')
  }
  return { totalLine, totalNumber }
}

/**
 * Look for the total: check all numbers and must be the higher of all of them
 * Check that the word tota
 *
 * @param Array
 *
 * @returns
 */
export const getItemPrices = (arr) => {
  const { subTotal, subTotalIndex, subTotalNumber } = findSubTotalValue(arr)
  const { totalLine, totalNumber } = findTotalValue(arr, subTotalIndex)
  return { total: totalLine, subTotal, totalNumber, subTotalNumber }
}

export const extractBillDetails = (text) => {
  const currencyRegex =
    /(?:(?:\$|€|£|¥|₹|₣|₱|₪|₨|₺|₽|₴|₸)\s?)?\d{1,3}(?:[,.]\d{3})*(?:[,.]\d{2})?/g
  const dateRegex = /\d{1,2}[-/]\d{1,2}[-/]\d{2,4}/

  const matches = text.match(currencyRegex)
  const dateMatch = text.match(dateRegex)
  let total = null
  let subTotal = null
  let taxes = null
  let billDate = null

  if (matches) {
    const numericMatches = matches.map((match) => {
      const cleanedMatch = match.replace(/[\$€£¥₹₣₱₪₨₺₽₴₸\s,]/g, '')
      return parseFloat(cleanedMatch)
    })

    if (numericMatches.length >= 3) {
      total = numericMatches.pop()
      subTotal = numericMatches.pop()
      taxes = numericMatches.reduce((sum, value) => sum + value, 0)
    } else if (numericMatches.length === 1) {
      total = numericMatches[0]
    }
  }

  if (dateMatch) {
    billDate = dateMatch[0]
  }

  return { total, subTotal, taxes, date: billDate }
}
