const initState = {}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action) => {
  if (action.type === 'updateCategories') {
    return action.payload
  }
  //   if (action.type === 'removeCategory') {
  //     return initState
  //   }
  return state
}
