import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import api from '../../configs/api'
import config from '../../configs/config'
import openModal from '../../actions/openModal'
import updateUser from '../../actions/updateUser'
import {
  getUser,
  postVerifyEmailWithToken,
  verifyTokenFromUrlLogout,
  resendTokenRequest,
} from '../requests'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Avatar from '@mui/material/Avatar'

import LoginLogout from './LoginLogout'
import Login from './Login'
import { getColors, getTheme } from '../theme'
import detectMobile from '../../hooks/detectMobile'
import VerifyEMailModal from '../../components/molecules/VerifyEmailModal'
import { getSwal } from '../notifications'
import logo from '../../images/logo.png'

export default function NavBar() {
  const dispatch = useDispatch()
  const isMobile = detectMobile()

  const user = useSelector((state) => state.user)
  const auth = useSelector((state) => state.auth)
  const name = user ? user.name : ''
  const imgSrc =
    user && user.photo ? `${api.users.getPhoto}/${user._id}/${user.photo}` : ''
  const colors = getColors(getTheme)[7]
  const [tokenSent, setTokenSent] = useState(false)

  const resendToken = async () => {
    setTokenSent(true)
    const user = await resendTokenRequest()
    if (user) {
      dispatch(updateUser(user))
    }
    if (user.isVerified) {
      setTokenSent(false)
      dispatch(openModal('close', ''))
    }
  }

  const showVerifyEmailModal = async (user) => {
    dispatch(
      openModal(
        'open',
        <VerifyEMailModal resendToken={(e) => resendToken()} user={user} />,
        false
      )
    )
  }

  const verifyTokenFromUrl = (token) => {
    setTokenSent(true)
    const user = postVerifyEmailWithToken(token)
    if (Object.keys(user).length) {
      dispatch(updateUser(user))
    }
    if (!Object.keys(user).length || !user.isVerified) {
      return false
    }
    return true
  }

  useEffect(() => {
    const userExist = Object.keys(user).length
    const queryParameters = new URLSearchParams(window.location.search)
    const token = queryParameters.get('token')
    const resetToken = queryParameters.get('resettoken')
    const email = queryParameters.get('email')
    //Regular Home Page or User Logged in
    dispatch(openModal('close', ''))
    //Reset Password
    if (resetToken && email && !user.isVerified) {
      dispatch(
        openModal(
          'open',
          <Login
            type="resetPassword"
            title="Reset Passwod"
            email={email}
            token={resetToken}
          />
        )
      )
      return
    }
    //Home Page with token
    else if (!auth.process && token) {
      verifyTokenFromUrlLogout(token)
      dispatch(updateUser(user))
      return
    }

    if (auth.process === 'register' && !user.isVerified) {
      setTokenSent(true)
    }

    if (userExist && !user.isVerified && !token) {
      showVerifyEmailModal(user)
      return
    }

    //Verify From token when user is logged in
    if (userExist && !user.isVerified && token) {
      const isVerifiedUser = verifyTokenFromUrl(token)
      if (!isVerifiedUser) {
        showVerifyEmailModal(user)
        return
      }
      if (isVerifiedUser) {
        dispatch(openModal('close', ''))
        if (token) {
          queryParameters.delete('token')
        }
      }
    }
  }, [auth.token])

  useEffect(() => {
    if (user.isVerified && tokenSent) {
      dispatch(updateUser(user))
      dispatch(openModal('close', ''))
      setTokenSent(false)
      getSwal(
        'Email Verified',
        'Congrats! your email has been verified!',
        'success'
      )
    }

    if (tokenSent) {
      const checkUser = setInterval(async () => {
        if (!user.isVerified && Object.keys(user).length) {
          const user = await getUser()
          dispatch(updateUser(user))
        } else if (user.isVerified) {
          clearInterval(checkUser)
        }
      }, config.timeInterval)

      return () => {
        clearInterval(checkUser)
      }
    }
  }, [user.isVerified, tokenSent])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ zIndex: '1201' }}>
        <Toolbar>
          {name ? (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              {imgSrc ? (
                <Link to={`/user`}>
                  <Avatar alt={name} src={imgSrc} />
                </Link>
              ) : (
                ''
              )}
            </IconButton>
          ) : (
            ''
          )}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {name ? (
              `Hi ${name}`
            ) : (
              <>
                {/*TODO REFACTOR*/}
                <img
                  style={{ position: 'absolute', marginTop: '3px' }}
                  src={logo}
                  title="TechBudget"
                  height="25"
                  alt="logo"
                />
                <span style={{ marginLeft: '33px' }}>TechBudget</span>
              </>
            )}
          </Typography>
          {!isMobile && name ? (
            <Typography
              variant="h5"
              sx={{ flexGrow: 1, color: colors, fontWeight: '500' }}
            >
              TechBudget
            </Typography>
          ) : (
            ''
          )}
          <LoginLogout email={auth.email} />
        </Toolbar>
      </AppBar>
    </Box>
  )
}
