import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'

import rootReducer from './reducers/rootReducer'
import reduxPromise from 'redux-promise'
import Spinner from './utility/Spinner/Spinner'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

// Redux Persist Setup
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' //default to local storage

import { PersistGate } from 'redux-persist/integration/react'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2' //'redux-persist/es/stateReconciler/autoMergeLevel2';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['siteModal'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const theStore = applyMiddleware(reduxPromise)(createStore)(persistedReducer)

const persistor = persistStore(theStore)

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Provider store={theStore}>
    <PersistGate loading={<Spinner />} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
