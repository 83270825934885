import React from 'react'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PeopleIcon from '@mui/icons-material/People'
import BarChartIcon from '@mui/icons-material/BarChart'
import LayersIcon from '@mui/icons-material/Layers'
import AssignmentIcon from '@mui/icons-material/Assignment'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'
import ReceiptIcon from '@mui/icons-material/Receipt'
import EditIcon from '@mui/icons-material/Edit'
import UploadFileIcon from '@mui/icons-material/UploadFile'
//import {DashboardIcon,PeopleIcon,LayersIcon,PhotoLibraryIcon,ReceiptIcon,EditIcon,UploadFileIcon} from '@mui/icons-material'

import Expenses from '../pages/Expenses'
import Crypto from '../pages/Crypto'
import Stocks from '../pages/Stocks'
import User from '../pages/User'
import Dashboard from '../pages/Dashboard'
import Category from '../pages/Category'
import Gallery from '../pages/Gallery/Gallery'
import Album from '../pages/Gallery/Album'
import AddTransaction from '../pages/AddTransaction'
import EditExpenses from '../pages/EditExpenses'

const MenuItemsList = [
  {
    name: 'Dashboard',
    route: '/',
    icon: <DashboardIcon />,
    element: <Dashboard />,
  },
  // {
  //   name: 'Categories',
  //   route: '/categories',
  //   icon: <LayersIcon />,
  //   element: <Category />,
  // },
  {
    name: 'Add Expenses',
    route: '/addtransaction',
    icon: <ReceiptIcon />,
    element: <AddTransaction />,
  },
  {
    name: 'Upload Expenses',
    route: '/expenses',
    icon: <UploadFileIcon />,
    element: <Expenses />,
  },

  {
    name: 'Edit Expenses',
    route: '/editexpenses',
    icon: <EditIcon />,
    element: <EditExpenses />,
  },
  // {
  //   name: 'Crypto',
  //   route: '/crypto',
  //   icon: <LayersIcon />,
  //   element: <Crypto />,
  // },
  // {
  //   name: 'Stocks',
  //   route: '/stocks',
  //   icon: <BarChartIcon />,
  //   element: <Stocks />,
  // },
  {
    name: 'User',
    route: '/user',
    icon: <PeopleIcon />,
    element: <User />,
  },
  // {
  //   name: 'Gallery',
  //   route: '/gallery',
  //   icon: <PhotoLibraryIcon />,
  //   element: <Gallery />,
  // },
]

const secondaryMenuItems = [
  {
    name: 'Current month',
    route: '/',
    icon: <AssignmentIcon />,
  },
  {
    name: 'Last quarter',
    route: '/',
    icon: <AssignmentIcon />,
  },
  {
    name: 'Year-end sale',
    route: '/',
    icon: <AssignmentIcon />,
  },
]

export default MenuItemsList
