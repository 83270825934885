import React from 'react'
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import detectMobile from '../hooks/detectMobile'

export default function TableOrder(props) {
  const isMobile = detectMobile()
  //TODO: Refactor
  const hasSource = (props) => {
    props.rows.forEach((row) => {
      if (row?.source) {
        return true
      }
    })
    return false
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Name</TableCell>
            {!isMobile ? (
              <>
                <TableCell>Decription</TableCell>
                <TableCell>Category</TableCell>
                {hasSource ? <TableCell>Pay. Option</TableCell> : ''}
              </>
            ) : (
              ''
            )}
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, i) => (
            <TableRow key={i}>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.name}</TableCell>
              {!isMobile ? (
                <>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>{row.category}</TableCell>
                  {row.source ? <TableCell>{row.source}</TableCell> : ''}
                </>
              ) : (
                ''
              )}
              <TableCell align="right">{`$${row.amount}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
