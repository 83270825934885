import moment from 'moment'
export const monthNames = {
  short: {
    en: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    fr: [
      'Jan',
      'Fev',
      'Mar',
      'Avr',
      'Mai',
      'Jun',
      'Jul',
      'Aou',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    es: [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic',
    ],
  },
  long: {
    en: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    fr: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ],
    es: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
  },
}

export const dayOfWeekNames = {
  short: {
    en: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ],
    fr: [
      'Dimanche',
      'Lundi',
      'Mardi',
      'Mercredi',
      'Jeudi',
      'Vendredi',
      'Samedi',
    ],
    es: [
      'Domingo',
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
    ],
  },
  long: {
    en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    fr: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    es: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
  },
}
/**
 * Returns an array of format 'Sun Oct 08 2023'
 * @param {
 * } date
 * @returns {String}
 */
export const getDateFormatted = (date) => {
  if (typeof date === 'object') {
    date = date.toDateString()
  }
  const d = date.replace(/-/g, '/').replace(/T.+/, '')
  return new Date(d).toDateString()
}

// Example usage:
// const currentDate = new Date();
// const formattedDate = formatDateToYYYYMMDD(currentDate);
// console.log(formattedDate); // Output will be something like "2023-10-21"
export const formatDateToYYYYMMDD = (date) => {
  return moment(date).format('YYYY-MM-DD')
}

export const getDateObj = (date) => {
  const d = date
    ? new Date(isNaN(date) ? date.replace(/-/g, '/') : date)
    : new Date()
  const monthIndex = d.getMonth()
  const dowIndex = d.getDay()

  const startOfMonth = moment(d).startOf('month').format('YYYY-MM-DD')
  const endOfMonth = moment(d).endOf('month').format('YYYY-MM-DD')

  return {
    date: d,
    day: parseInt(moment(date).format('DD')),
    dowIndex,
    dow: {
      short: {
        en: dayOfWeekNames.short.en[dowIndex],
        fr: dayOfWeekNames.short.fr[dowIndex],
      },
      long: {
        en: dayOfWeekNames.long.en[dowIndex],
        fr: dayOfWeekNames.long.fr[dowIndex],
      },
    },
    monthIndex,
    month: monthIndex + 1,
    monthNumber: monthIndex + 1 < 10 ? `0${monthIndex + 1}` : monthIndex + 1,
    monthName: {
      short: {
        en: monthNames.short.en[monthIndex],
        fr: monthNames.short.fr[monthIndex],
      },
      long: {
        en: monthNames.long.en[monthIndex],
        fr: monthNames.long.fr[monthIndex],
      },
    },
    year: d.getFullYear(),
    yearShort: parseInt(d.getFullYear().toString().slice(2)),
    YYYYMMDD: moment(d).format('YYYY-MM-DD'),
    startOfMonth,
    endOfMonth,
  }
}

/**
 * It gives an object containing actual date information such as month names in different formats, years, etc
 *
 */
export const Today = getDateObj()
