import { getDateObj } from '../date'
import moment from 'moment'

//This should be done by the API
export const setTransactionsFields = (trans, lang = 'en') => {
  trans.date = moment.utc(trans.date).format('YYYY-MM-DD')
  trans.category = trans.category?.name
  const dateObj = getDateObj(trans.date)
  trans.day = dateObj.day
  trans.month = dateObj.month
  trans.monthShort = dateObj.monthName.short[lang]
  trans.year = dateObj.year
  trans.dateLong = dateObj
  return trans
}
