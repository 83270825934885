import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import setTransactions from '../../actions/setTransactions'
import { removeObjectWithId } from '../../utility/objectArrays'

import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ClickAwayListener,
  IconButton,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import detectMobile from '../../hooks/detectMobile'
import TablecellInteractive from '../molecules/TableCellInteractive'

export default function TableExcel(props) {
  const dispatch = useDispatch()
  const [rowIndex, setRowIndex] = useState(-1)
  const [columnIndex, setColumnIndex] = useState(-1)

  const resetRowsColumns = () => {
    setRowIndex(-1)
    setColumnIndex(-1)
  }
  const isMobile = detectMobile()
  //TODO: Refactor
  const fieldsDesktop = [
    { label: 'Date', name: 'date', type: 'date' },
    { label: 'Name', name: 'name', type: 'text' },
    { label: 'Description', name: 'description', type: 'text' },
    { label: 'Category', name: 'category', type: 'select' },

    { label: 'Pay. Option', name: 'source', type: 'text' },
    { label: 'Cost', name: 'cost', type: 'text' },
    { label: 'Amount', name: 'amount', type: 'amount' },
    // {name: 'action',type: 'buttons'}
  ]

  const fieldsMobile = [
    { label: 'Date', name: 'date', type: 'date' },
    { label: 'Name', name: 'name', type: 'text' },
    { label: 'Category', name: 'category', type: 'select' },
    { label: 'Amount', name: 'amount', type: 'amount' },
  ]

  const fields = isMobile ? fieldsMobile : fieldsDesktop

  const TableRowCellsHeader = (props) => {
    return props.fields.map((field, i) => {
      return <TableCell key={i}>{field.label}</TableCell>
    })
  }
  const TableRowCells = (props) => {
    return (
      <>
        {props.fields.map((field, colIndex) => (
          <TablecellInteractive
            key={colIndex}
            rowIndex={props.i}
            columnIndex={colIndex}
            name={field.name}
            transaction={props.transaction}
            rowIndexSelected={rowIndex}
            columnIndexSelected={columnIndex}
            setRowIndex={props.setRowIndex}
            setColumnIndex={props.setColumnIndex}
            resetRowsColumns={props.resetRowsColumns}
            updateTransaction={props.updateTransaction}
          />
        ))}
      </>
    )
  }

  const handleRemove = async (e, transaction) => {
    const transactions = removeObjectWithId(props.rows, transaction.id)
    props.removeTransaction(transaction, transactions)
  }

  if (!props.rows.length)
    return <h3>{props.description || 'No transactions at the present time'}</h3>

  return (
    <>
      <ClickAwayListener onClickAway={() => resetRowsColumns()}>
        <TableContainer>
          <Table sx={{ tableLayout: 'auto' }}>
            <TableHead>
              <TableRow>
                <TableRowCellsHeader fields={fields} />
                <TableCell key={fields.length}>actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.rows.map((row, i) => (
                <TableRow key={i}>
                  <TableRowCells
                    fields={fields}
                    i={i}
                    transaction={row}
                    setRowIndex={setRowIndex}
                    setColumnIndex={setColumnIndex}
                    resetRowsColumns={resetRowsColumns}
                    updateTransaction={props.updateTransaction}
                  />
                  <TableCell>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      title="Delete"
                      onClick={(e) => handleRemove(e, row)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>

                  {/* <TableCell align="right">{`$${row.amount}`}</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ClickAwayListener>
    </>
  )
}
