import React from 'react'
import { TableCell, TextField, Tooltip, Box } from '@mui/material'

const handleTextFieldChange = (name, value, transaction) => {
  if (!transaction[name + 'Prev']) {
    transaction[name + 'Prev'] = transaction[name]
  }
  transaction[name] = value
}

const colorChange = (transaction, name) => {
  return !transaction[`${name}Prev`] ||
    transaction[`${name}Prev`] === transaction[name]
    ? 'default'
    : 'green'
}

const tooltipTitle = (transaction, name) => {
  const text = 'original: ' + transaction[`${name}Prev`]
  return typeof transaction[`${name}Prev`] === 'undefined' ||
    transaction[`${name}Prev`] === transaction[name]
    ? ''
    : text
}

const clickCell = (rowIndex, columnIndex, setRowIndex, setColumnIndex) => {
  setRowIndex(rowIndex)
  setColumnIndex(columnIndex)
}

const onEnter = (e, props) => {
  //
  if (e.key === 'Enter') {
    props.resetRowsColumns()
  }
  props.updateTransaction(props.transaction)
}

const mapMaxWidth = (field) => {
  if (['name', 'description'].includes(field)) {
    return '150px'
  }
  if (['amount'].includes(field)) {
    return '90px'
  }
  if (['date'].includes(field)) {
    return '130px'
  }
  return '110px'
}
/**
 *
 * TablecellInteractive is an editable cell, if clicked it is an input
 * @param {*} props = {rowIndex,columnIndex,transaction,name}
 * @returns
 */
export default function TablecellInteractive(props) {
  const firstCond =
    props.rowIndexSelected === props.rowIndex &&
    props.columnIndexSelected === props.columnIndex
  return (
    <TableCell
      onClick={() => {
        clickCell(
          props.rowIndex,
          props.columnIndex,
          props.setRowIndex,
          props.setColumnIndex
        )
      }}
    >
      {firstCond ? (
        <TextField
          defaultValue={props.transaction[props.name]}
          onChange={(e) =>
            handleTextFieldChange(
              [props.name],
              e.target.value,
              props.transaction
            )
          }
          sx={{ maxWidth: mapMaxWidth(props.name) }}
          onKeyPress={(e) => {
            onEnter(e, props)
          }}
        />
      ) : (
        <Tooltip
          title={tooltipTitle(props.transaction, [props.name])}
          placement="top-start"
          arrow
        >
          {/*TODO  props.isAmount ? '$': '' +  */}
          <Box
            sx={{
              color: colorChange(props.transaction, [props.name]),
              maxWidth: mapMaxWidth(props.name),
            }}
          >
            {props.transaction[props.name]}
          </Box>
        </Tooltip>
      )}
    </TableCell>
  )
}
