import React from 'react'
import { Bar } from 'react-chartjs-2'
import Title2 from './Title'
import { useTheme } from '@mui/material/styles'
import {
  createBarChartData,
  createBarChartDataSingle,
} from '../utility/datasets'
import Spinner from '../utility/Spinner/Spinner'
import NoData from './atoms/NoData'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: 'Expenses Category',
    },
  },
}

export default function BarChart(props) {
  const theme = useTheme()
  //TODO: Temporary, abstract more this part
  const barChartData = !props.singleDataSet
    ? createBarChartData(theme, props.data)
    : createBarChartDataSingle(theme, props.data)
  if (props.loading) {
    return <Spinner />
  }
  return (
    <React.Fragment>
      <Title2>{props.title}</Title2>
      {!barChartData.datasets.length ? (
        <NoData />
      ) : (
        <Bar options={options} data={barChartData} />
      )}
    </React.Fragment>
  )
}
