import React, { useState } from 'react'
import FormData from 'form-data'

import { useDispatch, useSelector } from 'react-redux'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme } from '@mui/material/styles'
import api from '../configs/api'
import Stack from '@mui/material/Stack'
import axios from 'axios'
import updateUser from '../actions/updateUser'
import { getSwal } from '../utility/notifications'

const renderTextField = (key, id, label, defaultValue, disabled = false) => {
  //if(!defaultValue) return (<></>)
  return (
    <TextField
      key={key}
      margin="normal"
      required
      variant="standard"
      fullWidth
      id={id}
      label={label}
      name={id}
      autoComplete={id}
      autoFocus
      defaultValue={defaultValue || ''}
      disabled={disabled}
    />
  )
}

const AllFields = (user) => {
  const fields = [
    {
      id: 'name',
      label: 'Name',
      value: user.name,
      disabled: false,
    },
    {
      id: 'lastname',
      label: 'Lastname',
      value: user.lastname,
      disabled: false,
    },
    {
      id: 'email',
      label: 'Email',
      value: user.email,
      disabled: true,
    },
  ]

  let allFields = []
  fields.forEach((field, key) => {
    allFields.push(
      renderTextField(key, field.id, field.label, field.value, field.disabled)
    )
  })
  return allFields
}

const UserFields = (user) => {
  return (
    <>
      <AllFields {...user} />
      {/* {renderTextField('imageUrl','Image URL','')} */}
      {/* {renderTextField('address','Address',user.address)}
            {renderTextField('city','City',user.city)}
            {renderTextField('postalCode','Postal Code',user.location.zipcode)} */}
    </>
  )
}

export default function User(props) {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)

  const [imageUrl, setImageUrl] = useState(null)
  const [originalImage, setOriginalImage] = useState(
    `${api.users.getPhoto}/${user._id}/${user.photo}`
  )
  const [fileImage, setFileImage] = useState(null)

  const picSize = 150

  const handleFileUpload = (event) => {
    const file = event.target.files[0]
    setFileImage(file)
    const reader = new FileReader()
    reader.onloadend = () => {
      setImageUrl(reader.result)
    }
    if (file) {
      reader.readAsDataURL(file)
    }
  }

  const uploadPhoto = async (e, formData) => {
    e.preventDefault()
    formData.append('file', fileImage)
    const url = `${api.users.getAll}/${user._id}/photo`

    try {
      const contentType = {
        headers: { 'content-type': 'multipart/form-data' },
        withCredentials: true,
      }
      const respImage = await axios.put(url, formData, contentType)
    } catch (error) {
      console.error('error', error)
      if (error?.response?.data?.error.includes('Upload limit reached')) {
        getSwal('Error', `File should not be larger than 1MB`)
        setImageUrl('')
      }
    }
  }

  const updateThisUser = async (formData) => {
    const resp = await axios.put(`${api.users.getAll}/${user._id}`, formData, {
      withCredentials: true,
    })
    return resp.data.data
  }

  const handleSubmit = async (event) => {
    try {
      if (user._id) {
        const formData = new FormData(event.currentTarget)
        await uploadPhoto(event, formData)
        const user = await updateThisUser(formData)
        dispatch(updateUser(user))
      }
    } catch (error) {
      console.error('ERRROROOOOR', error)
      getSwal('Error', `File should not be larger than 1MB`)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar
          sx={{ width: picSize, height: picSize }}
          src={imageUrl || originalImage}
        />

        <Typography component="h1" variant="h5">
          {user.name}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <label htmlFor="file">
              <Button variant="contained" component="span">
                Upload
              </Button>
              <input
                id="file"
                hidden
                accept="image/*"
                type="file"
                multiple
                onChange={handleFileUpload}
              />
            </label>
          </Stack>
          <UserFields {...user} />

          {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Update
          </Button>
        </Box>
      </Box>
    </Container>
  )
}
