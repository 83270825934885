import * as React from 'react'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Title from './Title'
import moment from 'moment'

function preventDefault(event) {
  event.preventDefault()
}

export default function Deposits(props) {
  const total = parseFloat(props.total || 0).toLocaleString(undefined, {
    minimumFractionDigits: 2,
  })
  return (
    <React.Fragment>
      <Title>{props.title}</Title>
      <Typography component="p" variant="h4">
        ${props.loading ? '-.--' : `${total}`}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1, display: 'none' }}>
        on {moment().format('ll')}
      </Typography>
      <div>
        <Link
          sx={{ display: 'none' }}
          color="primary"
          href="#"
          onClick={preventDefault}
        >
          View balance
        </Link>
      </div>
    </React.Fragment>
  )
}
