import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import setBill from '../../actions/setBill'
import TextField from '@mui/material/TextField'
import { useFormContext } from 'react-hook-form'

export default function AmountElement(props) {
  const { register, clearErrors, setValue } = useFormContext()
  const dispatch = useDispatch()
  const bill = useSelector((state) => state.bill)
  const [amount, setAmount] = useState(props.amount)
  const [errors, setErrors] = useState(props.errors)
  const c = 'color: orange'

  useEffect(() => {
    setErrors(props.errors)
  }, [props.errors])

  useEffect(() => {
    setValue(props.amount)
    setAmount(props.amount)
  }, [props.amount])

  const helperTextAmount = (amount, errors) => {
    if (amount == 0 && amount !== '') return 'Amount must be greater than 0'
    if (isNaN(amount) && amount !== '') return 'Invalid entry'
    if (errors && typeof errors.message !== 'undefined') return errors.message
    return ''
  }

  const errorAmount = (amount, errors) => {
    return (
      !!errors ||
      (amount == 0 && amount !== '') ||
      (isNaN(amount) && amount !== '')
    )
  }

  const checkSetAmount = (val) => {
    if (!isNaN(val)) {
      setAmount(val)
      dispatch(setBill({ amount: val, ...bill }))
      setValue('amount', val, { shouldValidate: true })
      clearErrors('amount')
    } else {
      setErrors({ type: 'required' })
    }
  }
  //
  return (
    <TextField
      required
      id="amount"
      name="amount"
      label="Amount"
      variant="standard"
      value={amount}
      autoComplete={amount}
      {...register('amount', { required: 'Amount is required', min: 0 })}
      error={errorAmount(amount, errors)}
      onChange={(e) => checkSetAmount(e.target.value)}
      helperText={helperTextAmount(amount, errors)}
    />
  )
}
