const initState = {
  date: '',
  amount: '',
  description: '',
  category: 'Fun',
  source: 'TD',
  type: 'Var',
}

export default (state = {}, action) => {
  if (action.type === 'setBill') {
    return action.payload
  }
  if (action.type === 'resetBill') {
    return initState
  }
  return state
}
