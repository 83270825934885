
export const getError = (methods, propsName) => {
  return !!methods.formState.errors[propsName]
}

export const getHelpError = (methods, propsName) => {
  return methods.formState.errors[propsName] &&
    typeof methods.formState.errors[propsName].message !== 'undefined'
    ? methods.formState.errors[propsName].message
    : ''
}