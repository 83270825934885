import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import './Modal.css'
import openModal from '../../actions/openModal'

class Modal extends React.Component {
  closeModal = (e) => {
    const allowToClose = this.props.siteModal.allowToClose || false
    if (allowToClose) {
      this.props.openModal('close', this.props.siteModal.content)
    }
  }

  render() {
    const display = this.props.siteModal.openClose === 'open' ? 'block' : 'none'
    let modalInlineStyle = { display }
    return (
      <div className="site-modal" style={modalInlineStyle}>
        <div className="modal-content">
          <div className="col right">
            <span onClick={this.closeModal} className="close">
              &times;
            </span>
          </div>
          <div>{this.props.siteModal.content}</div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    siteModal: state.siteModal,
  }
}

function mapDispatchToProps(dispatch) {
  // This function returns the bindActionCreators
  // and we hand bindActionCreators an object
  // each property will be a local prop
  // each value will be a function that will be dispatched when it runs
  // 2nd arg for baindActionCreators is the dispatch
  return bindActionCreators(
    {
      // updateModal: updateModal,
      openModal: openModal,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
