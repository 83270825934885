import React from 'react'
import './Spinner.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner} from '@fortawesome/free-solid-svg-icons';

export default function Spinner () {
    return (
        <div className="spinner-wrapper">
            <FontAwesomeIcon icon={faSpinner} size="6x" spin />
        </div>       
    )
}