import React, { useEffect, useState } from 'react'
import Title from '../../src/components/Title'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import StyledTextarea from '../../src/components/StyledTextArea'
import { createWorker } from 'tesseract.js'
import setBill from '../../src/actions/setBill'
import { useDispatch } from 'react-redux'
import resetBill from '../../src/actions/resetBill'
import CircularSpinner from './atoms/CircularSpinner'
import { getSwal } from '../utility/notifications'
import { Today } from '../utility/date'
import { validateDate } from '../utility/validations'

import {
  getItemPrices,
  getName,
  getDate,
} from '../../src/utility/dataExtract/bill'

export default function PicReader(props) {
  const dispatch = useDispatch()

  const [fileImage, setFileImage] = useState('')
  const [text, setText] = useState('')
  const [lines, setLines] = useState([])
  const [spinner, setSpinner] = useState(false)
  const [spinnerImage, setSpinnerImage] = useState(false)

  const handleFileUpload = async (event) => {
    resetValues()
    setSpinnerImage(true)
    const file = event.target.files[0]
    const reader = new FileReader()
    try {
      reader.onloadend = () => {
        setFileImage(reader.result)
      }
      reader.readAsDataURL(file)
    } catch (error) {
      getSwal('Error', 'There was an error uploading the file')
    }
    setSpinnerImage(false)
  }

  const convertImageToText = async () => {
    setSpinner(true)
    try {
      if (fileImage) {
        const worker = await createWorker()
        await worker.load()
        await worker.loadLanguage('eng')
        await worker.initialize('eng')
        const { data } = await worker.recognize(fileImage)
        setLines(data.lines)
        setText(data.text)
        const { totalNumber } = getItemPrices(data.lines)
        if (data.lines.length < 4) {
          return false
        }
        const name = getName(data.lines)
        const date = getDate(data.text)

        const obj = { name, amount: totalNumber }

        const val = validateDate(date)

        obj.date = val ? date : Today.YYYYMMDD

        dispatch(setBill(obj))
      }
    } catch (e) {
      console.error(`Worker failed: `, e)
    }
    setSpinner(false)
  }

  useEffect(() => {
    convertImageToText()
  }, [fileImage])

  const resetValues = () => {
    setFileImage('')
    setText('')
    dispatch(resetBill())
  }

  useEffect(() => {
    //resetValues() //
    setFileImage('')
    setText('')
  }, [])

  return (
    <Container
      maxWidth="xs"
      sx={{ ml: '0', pl: '0', p: 0, display: 'flex', flexDirection: 'column' }}
    >
      <Title>Upload Your Bill Picture</Title>
      <Stack direction="column" alignItems="center" spacing={2}>
        <Box sx={{ display: 'block' }}>
          {spinnerImage ? <CircularSpinner /> : ''}
          {fileImage ? (
            <img src={fileImage} alt="invoice pic" width="200" height="270" />
          ) : (
            ''
          )}
        </Box>
        <Container
          maxWidth="sm"
          sx={{
            marginTop: 2,
            marginLeft: 0,
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <label htmlFor="file">
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              component="span"
            >
              Upload
            </Button>
            <input
              id="file"
              hidden
              accept="image/*"
              type="file"
              multiple
              onChange={handleFileUpload}
            />
          </label>
          <label htmlFor="reset">
            <Button
              color="grey"
              variant="contained"
              component="span"
              id="reset"
              onClick={resetValues}
            >
              Reset
            </Button>
          </label>
        </Container>
        {/* </div> */}
      </Stack>
      <br />
      {spinner ? <CircularSpinner /> : ''}
      {text ? <StyledTextarea defaultValue={text} /> : ''}
      {/* <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
          Save
        </Button> */}
      {/* </Box> */}
    </Container>
  )
}
