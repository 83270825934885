//const key = `MARKETSTACK_KEY`
//const apiUrl = `http://api.marketstack.com/v1/eod`
//const apiUrlKey = `${apiUrl}?access_key=${key}`

//REFACTOR TO API FILE
const apiLocalUrl = 'http://localhost:5000'
const apiServerUrl = 'https://techbudget.io/api'

const api = () => {
  return window.location.hostname === 'localhost' ? apiLocalUrl : apiServerUrl
}

const config = {
  //apiMarketstack: apiUrlKey,
  //MOVE APIS TO api  file
  api: api(),
  apiGogogol: 'https://www.gogogol.com/lib/endpoints',
  lastLimit: 30,
  fileRowsLimit: 1000,
  timeInterval: 2000,
  exportFile: {
    name: 'TechBudgetFile',
    extension: 'xlsx',
    sheet: 'Techbudget',
  },
}

export default config
