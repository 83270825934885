export const matchLabelNames = (name) => {
  if (name.match(generateWildcardRegex('karina'))) return 'Metro'
  if (name.match(/(KARINA)?(\s)?MUNOZ/)) {
    return 'Metro'
  }
  if (name.match(/1325:?\savenue\sLaurier/i)) return 'SAQ'
}

const generateWildcardRegex = (pattern) => {
  const wildcardPattern = pattern.replace(/./gi, '.$&')
  const regex = new RegExp(`^${wildcardPattern}$`)
  return regex
}
