export const getTheme = {
  palette: {
    primary: {
      main: '#2F4858',
    },
    secondary: {
      main: '#006B86',
    },
    success: {
      main: '#24F30D',
    },
  },
}
//TODO Get values from _variables.scss
export const getColors = (theme) => {
  return [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    '#00909E',
    '#445a69',
    '#596d79',
    '#00B497',
    '#007A66',
    '#00D670',
    '#007e6a',
    '#169C07',
    '#1CC309',
    '#169C07',
    '#138806',
    '#0E6104', //
  ]
}
//TODO delete
export const Colors = {
  main: '#2F4858',
  secondary: '#006B86',
  green: 'rgb(36,243,13)',
  greenLight: 'rgb(0, 214, 112)',
}
