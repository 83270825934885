import api from '../configs/api'
import config from '../configs/config'
import axios from 'axios'

export const getUser = async () => {
  try {
    const { data } = await axios.get(api.auth.getMe, {
      withCredentials: true,
    })
    const user = data.data
    if (user) {
      return user
    }
  } catch (error) {
    console.error(`getUser error`, error)
  }
  return {}
}

export const fetchTransactions = async (query = '') => {
  try {
    const defaultQuery = `${api.transactions.transaction}/?limit=${config.fileRowsLimit}&sort=-date`
    const myQuery = !query ? defaultQuery : `${defaultQuery}&${query}`
    const { data } = await axios.get(myQuery, {
      withCredentials: true,
    })
    const transactions = data.data
    if (transactions) {
      return transactions
    }
  } catch (error) {
    console.error(`fetchTransactions error`, error)
  }
  return []
}

export const postVerifyEmailWithToken = async (token) => {
  try {
    const resp = await axios.post(
      `${api.auth.verifyEmail}`,
      { token },
      { withCredentials: false }
    )
    const user = resp?.data?.data
    if (user) {
      return user
    }
  } catch (error) {
    console.error(`fechVerifyEmail error`, error)
  }
  return {}
}

export const verifyTokenFromUrlLogout = async (token) => {
  try {
    const resp = await axios.post(
      `${api.auth.verifyEmail}`,
      { token },
      { withCredentials: false }
    )
    const user = resp?.data?.data
    return user
  } catch (error) {
    console.error('verifyTokenFromUrlLogout error ', error)
  }
  return {}
}

export const resendTokenRequest = async () => {
  try {
    const resp = await axios.put(
      `${api.auth.resendToken}`,
      {},
      { withCredentials: true }
    )
    //TODO: change to resp.data.data
    const user = resp?.data?.data?.user
    if (user) {
      return user
    }
  } catch (error) {
    console.error(`resendTokenRequest error`, error)
  }
  return {}
}

export const fetchCategories = async () => {
  try {
    const url = `${api.categories.category}/?limit=${config.fileRowsLimit}&sort=-date`
    const { data } = await axios.get(url, {
      withCredentials: true,
    })
    const categories = data.data.map((item) => item.name)
    return categories
  } catch (error) {
    console.error(`fetchCategories error`, error)
  }
  return []
}
