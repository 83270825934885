import React, { useState } from 'react'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import { useSelector } from 'react-redux'
import api from '../configs/api'

export default function StandardImageList(props) {
  const [toggleImage, setToggleImage] = useState(true)

  const selectImage = (e) => {
    const img = e.target
    // const actualToggle = !toggleImage
    if (toggleImage) {
      img.style.transform = 'scale(3)'
      img.style.transition = 'transform 0.25s ease'
      img.style.zIndex = '100000'
    } else {
      img.style.transform = 'scale(1)'
      img.style.transition = 'transform 0.25s ease'
      img.style.zIndex = 'unset'
    }
    setToggleImage(!toggleImage)
  }
  const user = useSelector((state) => state.user)
  const imgSrc = `${api.users.getPhoto}/${user._id}/${props.album}`

  if (!props.files) {
    return ''
  }
  return (
    <>
      <ImageList
        sx={{ width: '100%', minHeight: '100%' }}
        cols={3}
        rowHeight={164}
        className="image-list"
      >
        {props.files.map((item) => (
          <ImageListItem key={item.name}>
            <img
              src={`${imgSrc}/${item.name}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${imgSrc}/${item.name}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title || item.name}
              loading="lazy"
              onClick={(e) => selectImage(e)}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  )
}
