const rulesTransactionObj = {
  category: {
    Food: {
      max: 500,
      min: 0,
    },
    Rent: {
      max: 10000,
      min: 700,
    },
    Others: {
      max: 1500,
      min: 1,
    },
    default: {
      max: 200,
      min: 1,
    },
    Travel: {
      max: 2000,
      min: 1,
    },
  },
}

export const getCategoryRules = (data) => {
  const cat = rulesTransactionObj.category
  return cat[data.category] ? cat[data.category] : cat.default
}
