import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import api from '../../configs/api'
import setFiles from '../../actions/setFiles'
import ImageList from '../../components/ImageList'
import { useParams } from 'react-router-dom'
import FsLightbox from 'fslightbox-react'
import { Button } from '@mui/material'

export default function Album(props) {
  const { album } = useParams()
  const name = album.charAt(0).toUpperCase() + album.slice(1)
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const [myFiles, setMyFiles] = useState([])
  const [counter, setCounter] = useState(0)
  const [toggler, setToggler] = useState(false)
  const [allImages, setAllImages] = useState([])

  useEffect(() => {
    const fectchFiles = async () => {
      const url = `${api.users.getAll}/${user._id}/files/${album}`
      const { data } = await axios.get(url, { withCredentials: true })
      setCounter(data.data.length)
      setMyFiles(data.data)
      dispatch(setFiles(data.data))
      const imgSrc = `${api.users.getPhoto}/${user._id}/${album}`
      const allImagesConst = data.data.map((file) => `${imgSrc}/${file.name}`)
      setAllImages(allImagesConst)
      return data.data
    }
    fectchFiles()
  }, [])
  return (
    <>
      <h2>
        Album from {props.name || name || 'nowhere'}, {counter} results
      </h2>
      <Button onClick={() => setToggler(!toggler)}>Open</Button>
      <FsLightbox toggler={toggler} sources={allImages} />
      <ImageList files={myFiles} album={album} />
    </>
  )
}
