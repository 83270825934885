import React, { useState, useEffect, cloneElement } from 'react'
import { TablePagination } from '@mui/material'
import Title from '../Title'
import { transFormObjectToArray } from '../../utility/objectArrays'
import Spinner from '../../utility/Spinner/Spinner'
import NoData from '../atoms/NoData'
import { getDateObj } from '../../utility/date'

export default function TableContainer(props) {
  const getRowOrders = (props) => {
    let rows = []
    if (!props.transactions) {
      return []
    }
    const transactions = transFormObjectToArray(props.transactions)
    if (typeof transactions !== 'object' || !transactions.length) {
      return []
    }

    transactions.forEach((fin) => {
      if (!fin.category) {
        return []
      }
      if (fin.category.name) {
        fin.category = fin.category.name
      }
      const { _id, date, name, description, category, amount, cost, source } =
        fin
      rows.push({
        id: _id,
        date: getDateObj(date.replace(/T.+/, '')).YYYYMMDD,
        name,
        description,
        category,
        source,
        cost,
        amount,
      })
    })
    return rows
  }

  const [rows, setRows] = useState([])
  const [counter, setCounter] = useState(0)
  const [originalRows, setOriginalRows] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const Component = (props) => {
    return cloneElement(props.children, { rows: props.rows })
  }

  const handleChangePage = (event, newPage) => {
    nextRowData(newPage)
    setPage(newPage)
  }

  const nextRowData = (newPage) => {
    const startIndex = newPage * rowsPerPage
    const endIndex = startIndex + rowsPerPage
    const newRows = originalRows.slice(startIndex, endIndex)
    if (newRows.length) {
      setRows(newRows)
    }
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value)
    setPage(0)
  }

  useEffect(() => {
    const allRows = getRowOrders(props)
    setOriginalRows(allRows)
    setCounter(allRows.length)
  }, [props.transactions.length])

  useEffect(() => {
    setRowsPerPage(rowsPerPage)
    const originalRows = getRowOrders(props)
    const newRows = originalRows.slice(0, rowsPerPage)
    setRows(newRows)
  }, [rowsPerPage, props.transactions])

  const title = props.title || 'Recent Expenses'
  if (props.loading) return <Spinner />
  return (
    <React.Fragment>
      <Title>{title}</Title>
      {!rows.length ? (
        <NoData />
      ) : (
        <>
          <Component children={props.children} rows={rows} />
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={counter}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </React.Fragment>
  )
}
