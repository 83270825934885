import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
//import ListSubheader from "@mui/material/ListSubheader";
//import AssignmentIcon from "@mui/icons-material/Assignment";
import "./listItems.css";

import { Link } from "react-router-dom";
import MenuItemsList from "../templates/MenuItemsList";

const MenuLists = (props) => {
  let list = [];
  props.items.forEach((item, i) => {
    list.push(
      <Link to={`${item.route}`} key={i}>
        <ListItemButton>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.name} />
        </ListItemButton>
      </Link>
    );
  });
  return list;
};

export const MainListItems = (
  <React.Fragment>
    <MenuLists items={MenuItemsList} />
  </React.Fragment>
);

// const secondaryMenuItems = [
//   {
//     name: 'Current month',
//     route: '/',
//     icon: <AssignmentIcon />
//   },
//   {
//     name: 'Last quarter',
//     route: '/',
//     icon: <AssignmentIcon />
//   },
//   {
//     name: 'Year-end sale',
//     route: '/',
//     icon: <AssignmentIcon />
//   },

// ]

// export const secondaryListItems = (
//   <React.Fragment>
//     <ListSubheader component="div" inset>
//       Saved reports
//     </ListSubheader>
//     <MenuLists items={secondaryMenuItems} />
//   </React.Fragment>
// );
