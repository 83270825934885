import React from 'react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Chart from '../../../components/Chart'
import Deposits from '../../../components/Deposits'
import BarChart from '../../../components/BarChart'

export default function DashboardGroup(props) {
  return (
    <>
      <Grid item xs={12}>
        <h3>{props.title}</h3>
      </Grid>
      {/* Chart */}
      <Grid item xs={12} md={8} lg={9}>
        <Paper
          sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 280 }}
        >
          <Chart
            title={props.chartData.title}
            data={props.chartData.data}
            keyX="day"
            keyY="amount"
            loading={props.loading}
          />
        </Paper>
      </Grid>
      {/* Total */}
      <Grid item xs={12} md={4} lg={3}>
        <Paper
          sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 280 }}
        >
          <Deposits
            title={props.totalData.title}
            total={props.totalData.data}
            loading={props.loading}
          />
        </Paper>
      </Grid>
      {/* BarChart */}
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <BarChart
            title={props.barData.title}
            data={props.barData.data}
            singleDataSet={true}
            loading={props.loading}
          />
        </Paper>
      </Grid>
    </>
  )
}
