import React from 'react'
import Grid from '@mui/material/Grid'
import params from '../configs/params'

import DashboardMonth from '../components/organisms/dashboards/DashboardMonth'
import DashboardMonthYear from '../components/organisms/dashboards/DashboardMonthYear'

export default function Dashboard() {
  //TODO Replace with i18n
  const lang = params.lang
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <h1>Dashboard</h1>
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <h2>Monthly Expenses</h2>
      </Grid>

      <DashboardMonth lang={lang} />

      {/* Total Expenses Year */}
      <Grid item xs={12} md={12} lg={12} sx={{ mt: 7 }}>
        <h2>Yearly Expenses</h2>
      </Grid>

      <DashboardMonthYear lang={lang} />
    </Grid>
  )
}
